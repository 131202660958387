@font-face {
  font-family: 'Museo Sans';
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/MuseoSans300.woff2) format('woff2'),
    url(./assets/fonts/MuseoSans300.woff) format('woff'),
    url(./assets/fonts/MuseoSans300.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/MuseoSans500.woff2) format('woff2'),
    url(./assets/fonts/MuseoSans500.woff) format('woff'),
    url(./assets/fonts/MuseoSans500.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/MuseoSans700.woff2) format('woff2'),
    url(./assets/fonts/MuseoSans700.woff) format('woff'),
    url(./assets/fonts/MuseoSans700.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 900;
  font-style: normal;
  src: url(./assets/fonts/MuseoSans900.woff2) format('woff2'),
    url(./assets/fonts/MuseoSans900.woff) format('woff'),
    url(./assets/fonts/MuseoSans900.otf) format('opentype');
}

@font-face {
  font-family: 'NeoTech';
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/NeoTech.otf) format('opentype');
}
